.mdWidthTables {

    span.down,
    span.up {
        position: relative;
        display: block;

        &:after {
            display: inline-block;
            color: rgba(0, 0, 0, 0.88);
            border-radius: 6px;
            text-align: center;
            padding: 0 1em;
        }

        &.up:after {
            background-color: #D8BFD8;
            content: "hits ↑";
        }

        &.down:after {
            background-color: #F0E68C;
            content: "hits ↓";
        }
    }

    table {
        display: table;
        text-align: start;
        border-radius: 8px 8px 0 0;
        border-collapse: separate;
        border-spacing: 0;
        min-width: 100%;
        table-layout: auto;

        tr:first-child>*:first-child {
            border-start-start-radius: 8px;
        }

        tr th {
            position: relative;
            padding: 16px 16px;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #fafafa;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
            white-space: nowrap;

            &:before {
                position: absolute;
                top: 50%;
                inset-inline-end: 0;
                width: 1px;
                height: 1.6em;
                background-color: #f0f0f0;
                transform: translateY(-50%);
                transition: background-color 0.2s;
                content: "";
            }
        }

        tr td {
            padding: 16px 16px;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
            // &:first-child, &:last-child {
            //   white-space: nowrap;
            // }
            white-space: nowrap;
        }

        tr:hover td {
            background: #f5f5f5;
        }
    }
}

.userGuideMd {
    h2 {
        margin: 0.5em 0;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 30px;
        line-height: 1.2666666666666666;
    }

    h3 {
        margin: 0.5em 0;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.3333333333333333;
    }

    h4 {
        margin: 0.5em 0;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
    }

    p {
        margin-bottom: 1em;
        color: rgba(0, 0, 0, 0.88);
        font-size: 16px;
        line-height: 1.5;
    }
}