.modelValidation {
    .visible {
        display: block;
    }

    .hidden {
        display: none;
        padding: 0;
        margin: 0;
    }

    .number {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: .4em;
        font-size: .5em;
        font-weight: 700;
        color: white;
        background-color: var(--defaultTitleColor);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all .2s ease-in-out;
        opacity: .3;

        &.validated {
            background-color: var(--colorGreenSuccess);
            opacity: 1;
        }
    }

    h4 {
        display: flex;
        align-items: center;
        margin-bottom: var(--defaultPadding);


    }

    .mvTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .centerButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: var(--defaultPadding);
    }


    .packagesContainer {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: var(--defaultPadding);

        .package {

            border-radius: var(--defaultBorderRadius);
            padding: var(--defaultPadding);
            cursor: pointer;
            position: relative;
            transition: all .2s ease-in-out;

            &:hover {
                opacity: .9;
            }

            &.selected {
                background-color: var(--colorGreenSuccess);
                box-shadow: 0px 3px 5px hsla(0, 0%, 0%, .1);

                &:after {
                    content: 'Selected';
                    display: block;
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    padding: 5px 10px;
                    background-color: white;
                    color: var(--defaultTitleColor);
                    border-radius: 0 var(--defaultBorderRadius) 0 var(--defaultBorderRadius);
                    font-weight: 600;
                }
            }

            &.noSelection {
                background-color: var(--defaultTitleColor);
            }

            &.notSelected {
                background-color: var(--defaultTitleColor);
                opacity: .6;
                transform: scale(.9);
            }

            h4 {
                color: white;
                margin-bottom: calc(var(--defaultPadding) / 2);
            }

            p {
                color: white;
                display: flex;
                justify-content: space-between;

                label {
                    font-weight: 600;
                    display: block;
                    color: white;
                }
            }
        }
    }

    .collections,
    .options {

        h4 {
            margin-bottom: 0;
        }
    }

    .records {
        .import {
            display: flex;
            align-items: center;
            gap: var(--defaultPadding);
        }
    }

    input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    input[type="file"]+label {
        font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: black;
        display: inline-block;
        cursor: pointer;
    }

    input[type="file"]:focus+label,
    input[type="file"]+label:hover {
        background-color: red;
    }

    .options {

        textarea {
            width: 100%;
        }

        h5 {
            margin: var(--defaultPadding) 0;
        }

        ul {
            margin-left: 1em;
            list-style-type: disc;
            text-transform: uppercase;
            font-size: .9em;

            li {
                margin: calc(var(--defaultPadding) / 2) 0;
            }
        }
    }

    .runData {


        .showOptions {
            margin-top: var(--defaultPadding);

            h5 {
                margin-bottom: var(--defaultPadding);
            }
        }
    }



    .chartContainer {
        width: 100%;
        margin-bottom: var(--defaultPadding);
        position: relative;
        height: 400px;
    }
}