.stats {
    .customTab {
        display: none;

        &.selected {
            display: block;
        }
    }

    .customTabSelector {

        margin-bottom: var(--defaultPadding);
        display: flex;
        justify-content: center;
        gap: 10px;

        .customTabButton,.customNegativeButton {
            font-size: .8rem !important;
            font-weight: 500;
        }
    }

    .charts {
        h2 {
            text-align: center;
        }

        h4 {
            margin: var(--defaultPadding) 0;
            text-align: center;
        }
    }

    .flexContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: var(--defaultPadding);
        margin-bottom: var(--defaultPadding);

        .paper {
            margin-bottom: 0;
        }

        .infoBox {
            text-align: center;
            line-height: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                color: var(--defaultTextColor);
                font-size: 1.6rem;
            }
        }
    }
    .stats-card-nav .ant-card-body {
        padding: 0;
    }
    .stats-card-nav .ant-tabs-nav {
        margin: 0 0 0 1rem;
    }
    .stats-card-nav .ant-card-actions li:first-of-type {
        padding: 0;
    }
}