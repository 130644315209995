 @mixin markdownFormatted {

     p,
     blockquote,
     ul,
     ol,
     dl,
     table,
     pre {
         margin: 15px 0;
     }

     ul,
     ol {
         padding-left: 30px;
     }

     ul {
         list-style-type: disc;

     }

     ol {
         list-style-type: decimal;
     }

     h1 {
         border-bottom: 1px solid #ddd;
         color: #000;
         font-size: 2em;
     }

     h2 {
         border-bottom: 1px solid #eee;
         color: #000;
         font-size: 1.5em;
     }

     h3 {
         font-size: 1.3em;
     }

     h4 {
         font-size: 1.2em;
     }

     h5 {
         font-size: 1.1em;
     }


     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
         font-weight: bold;
         line-height: 1.7;
         margin: 1em 0 15px 0;
     }

     h1+p,
     h2+p,
     h3+p {
         margin-top: 10px;
     }

     img {
         max-width: 100%;
     }

     code,
     pre {
         background-color: #F8F8F8;
         border-radius: 3px;
         border: 1px solid #DDD;
         font-family: Consolas, "Liberation Mono", Courier, monospace;
         font-size: 12px;
         margin: 0 2px;
         padding: 0 5px;
         white-space: pre;
     }

     pre code {
         border: none;
         margin: 0;
         padding: 0;
         white-space: pre-wrap;
     }

     strong {
         font-weight: bold;
     }

     i {
         font-style: italic;
     }
 }