@import '../common.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index:999999;

    .bgModal {
        width: 100vw;
        height: 100vh;
        background-color: hsla(0, 0%, 0%, .5);
    }

    .modalContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .modalContent {
            @extend .paper;
            
            padding: var(--defaultPadding);
            width: 500px;
            height: auto;

            @include mediaSm {
                width: auto;
                min-width: 170px;
                max-width: 80vw;
            }

            p {
                margin: var(--defaultPadding) 0;
            }

            input {
                width: 50%;
                border: 1px solid silver;
                border-radius: var(--defaultBorderRadius);
                padding: 5px;
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                gap: var(--defaultPadding);
            }
        }


    }
}