@mixin antFormatted {
    color: var(--ant-color-text);
    word-break: break-word;
    line-height: var(--ant-line-height);
    font-family: var(--ant-font-family);
    font-size: var(--ant-font-size);

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: var(--ant-color-text-heading);
        font-weight: var(--ant-font-weight-strong);
        margin: 1em 0;


    }

    h1 {
        font-size: var(--ant-font-size-heading-4);
        line-height: var(--ant-line-height-heading-4);
    }

    h2 {
        font-size: var(--ant-font-size-heading-5);
        line-height: var(--ant-line-height-heading-5);
    }

    // h1 {
    //     font-size: var(--ant-font-size-heading-1);
    //     line-height: var(--ant-line-height-heading-1);
    // }

    // h2 {
    //     font-size: var(--ant-font-size-heading-2);
    //     line-height: var(--ant-line-height-heading-2);
    // }

    // h3 {
    //     font-size: var(--ant-font-size-heading-3);
    //     line-height: var(--ant-line-height-heading-3);
    // }

    // h4 {
    //     font-size: var(--ant-font-size-heading-4);
    //     line-height: var(--ant-line-height-heading-4);
    // }

    // h5 {
    //     font-size: var(--ant-font-size-heading-5);
    //     line-height: var(--ant-line-height-heading-5);
    // }

    p {
        color: var(--ant-color-text);
        word-break: break-word;
        line-height: var(--ant-line-height);
        margin-top: 1.2em;
        margin-bottom: .5em;
    }

    code,
    pre {
        background-color: #F8F8F8;
        border-radius: 3px;
        border: 1px solid #DDD;
        font-family: Consolas, "Liberation Mono", Courier, monospace;
        font-size: inherit;
        margin: 0 2px;
        padding: 5px 5px;
        white-space: pre;
    }

    pre code {
        border: none;
        padding: 0;
        white-space: pre-wrap;
        margin: 1em 0;
    }

    strong {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    ul,
    ol {
        padding-left: 30px;
        margin:1em 0;
    }

    ul {
        list-style-type: disc;

    }

    ol {
        list-style-type: decimal;
    }

}