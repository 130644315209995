@import "mixins.scss";

.horizontalMenu {
    // .ant-menu-submenu-title,.ant-menu-title-content {
    //     color: white !important;
    // }

    justify-content: center;
    font-weight: 400;

    .caretIcon {
        margin-left: 5px;
    }
}

.ant-menu-sub {
    .caretIcon {
        display: none;
    }
}

// li.ant-menu-item-danger {
    // &:after {
        // content: " (Neterium)";
        // font-size: .8em;
    // }

    // &:first-of-type {
    //     border-top: 1px solid red;
    // }
// }