@import "MarkdownFormatted.scss";
@import "mixins.scss";

.news {
    .ant-list-item-meta-title {
        color: var(--defaultNeteriumColor) !important;
        
        font-size: 1.6rem !important;
    }

    .md {
        @include markdownFormatted;
    }

    .envPill {
        color: white;

        &.all {
            background-color: var(--defaultBg);
            display: none;
        }

        &.dev {
            background-color: var(--colorEnvDev);
        }

        &.on_premise {
            background-color: var(--colorEnvOnPremise);
        }

        &.prod {
            background-color: var(--colorEnvProd);
        }

        &.test {
            background-color: var(--colorEnvTest);
        }

        &.sandbox {
            background-color: var(--colorEnvSandbox);
        }
    }

    time {
        font-style: italic;
    }

        // .newsItem {
        //     .newsContent {
        //         display: flex;
        //         flex-direction: column;
        //         gap: 1rem;
        //         .newsArticle {
        //             display: flex;
        //             flex-direction: row;
        //             flex: 1;
        //         }

        //         .newsTitle {
        //             font-size: 1.2rem;
                    
        //             color: var(--defaultNeteriumColor);
        //             color:red;
        //         }

        //         .readMore {
        //             display: flex;
        //             flex-direction: column;
        //             justify-content: flex-end;
        //         }
        //     }
        // }

    .newsCard{
        display: flex;
        flex-direction: column;
        .ant-card-body{
            display: flex;
            flex-direction: column;
            height:100%
        }
        .newsTitle {
            font-size: 1.2rem;
            color: var(--defaultNeteriumColor);
        }
        .newsContent {
            display: flex;
            flex-direction: row;
            flex: 1;
        }
        
    }
}