.errorPage {
    padding: var(--defaultPadding);
    margin: 0 auto;
    width: 50%;
    margin-top: 30px;
    font-size: .8rem;
    text-align: center;
    p{
        padding: var(--defaultPadding) 0;
    }
    >div{
        margin: var(--defaultPadding) 0;
    }
    a{
        text-decoration: none !important;
    }

}