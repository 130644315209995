.profileDetails {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: var(--defaultGap);

    ul {
        padding: 1em 0 1em 1em;
    }

    h2,
    h3 {
        font-weight: bold;
        margin-top: 1em;
        color:  var(--colorSecondary);
    }
}
.showJsonButton {
    margin-bottom: var(--defaultGap);
}