.nsadmin{
    .saTitle {
        >div {
            .archivedButton {
                margin-right: var(--defaultPadding);
            }
        }
    }

    .saList {
        >h4 {
            padding-bottom: var(--defaultPadding);
        }
    }

    .serviceAccount {
        // position: relative;
        // border-bottom-width: 4px;
        // border-bottom-style: solid;
        // &.active {
        //     border-bottom-color: var(--colorGreenSuccess);
        // }
        // &.inactive {
        //     border-bottom-color: var(--colorOrangeWarning);
        // }
        // &.noAuth {
        //     border-bottom-color: var(--colorOrangeWarning);

        // }
        // &.archived {
        //     border-bottom-color: #2790f9;
        // }


        // .customSuperSmallButton {
        //     margin-top: 1em;
        // }

        .status {
            font-size: 1rem;

            .serviceAccountPill {
                display: block;
                width: 100%;
                // border-bottom: 2px solid currentColor;
                

                svg {
                    margin-right: .3em;
                }
            }

        }

        .serviceAccountPill {
            &.green {
                color: var(--colorGreenSuccess);
            }

            &.red {
                color: var(--colorRedFailure);
            }

            &.active {
                color: var(--colorGreenSuccess);
            }

            &.inactive {
                color: var(--colorOrangeWarning);
            }

            &.archived {
                color: #2790f9;
            }

        }

        .customTable {
            margin: var(--defaultPadding) 0;
            font-size: .7rem;
        }

        div.saActions {
            display: flex;
            justify-content: flex-end;
            gap: calc(var(--defaultPadding)/2);

        }
    }
}
