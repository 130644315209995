.createProject,.createNamespace,.createPartner,.createuser,.createServiceAccount,.inviteUsers {
    padding: var(--defaultPadding);
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);

    div.formGroup {
        display: flex;

        label {
            width: 120px;
        }

        input,
        select {
            flex: 1;
            border: 1px solid silver;
            border-radius: var(--defaultBorderRadius);
            padding: 5px;
        }


    }

    .actions {
        display: flex;
        justify-content: flex-end;
        gap:var(--defaultPadding);
    }
}