// Extra small devices (portrait phones, less than 576px)
@mixin mediaXs {
    @media (max-width: 575.98px) { @content; }
}

// Small devices (landscape phones, 576px and up)
@mixin mediaSm {
    @media (min-width: 576px) { @content; }
}

// Medium devices (tablets, 768px and up)
@mixin mediaMd {
    @media (min-width: 768px) { @content; }
}

// Large devices (desktops, 992px and up)
@mixin mediaLg {
    @media (min-width: 992px) { @content; }
}

// Extra large devices (large desktops, 1200px and up)
@mixin mediaXl {
    @media (min-width: 1200px) { @content; }
}














