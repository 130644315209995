.tables {
    display: flex;
    gap: var(--defaultPadding);

    .statsTable {
        flex: 1;
    }

    .total {
        background-color: var(--defaultTitleColor);
            color: white;
            text-align: right;
        &:first-child {
            text-align: left;
        }
    }

    .mvCompareTable {
        .green {
            background-color: #5b8c00;
        }

        .lightGreen {
            background-color: #a0d911;
            color: #333333;
        }

        .orange {
            background-color: #fa8c16;
            color: #333333;
        }

        .yellow {
            background-color: #ffec3d;
            color: #333333;
        }
        .lightRed{
            background-color: #ffa39e;
            color: #333333;
        }

        .red {
            background-color: #f5222d;
        }
        .darkRed{
            background-color: #820014;
        }
    }
}