.serviceAccountFormAnt {
    .ant-checkbox-group{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }
    .ant-card,
    .ant-checkbox-wrapper {
        width: 100%;
    }

    .ant-checkbox-wrapper span {
        &:first-child{
            display:none;
        }
        &:last-child {
            width: 100%;
            display: block;
        }

    }

    .package {


        transition: all .2s ease-in-out;

        &:hover {
            opacity: .9;
        }

        

        label {
            font-weight: 600;
            display: block;

        }

        ul {
            list-style-type: none;
            text-align: right;

            li {
                small {
                    font-style: italic;
                    font-weight: 500;
                }
            }
        }

        p {
        
            display: flex;
            justify-content: space-between;
            text-align: left;
        }
    }

    .ant-checkbox-wrapper-checked {
        .ant-card {
            position:relative;
            background-color: var(--defaultTitleColorSemiOpaque);
            &:after{
                content:'Selected';
                position:absolute;
                top:0;
                right:0;
                background-color: var(--defaultTitleColor);
                padding: 10px;
                color: white;
            }
        }
    }
}
