@import 'mixins.scss';

/**
 * Applies padding to the top of the layout to create space for a fixed navigation bar.
 */
.layoutTop {
    padding-top: 121px;

    .mobileMenu {
        display: block;

        @include mediaLg {
            display: none;
        }
    }
}

/**
 * Sets the minimum height of the app layout.
 * By using `calc`, it ensures that the layout takes up the full viewport height minus the height of the fixed navigation bar.
 */
.appLayout {
    min-height: calc(-107px + 100vh);
    //background-color: var(--defaultBgContent);
    width: 100%;

    @include mediaLg {
        min-height: calc(-121px + 100vh);
    }

    .mainContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100vw;

        flex: 1;

        .mainContentWrapper {
            flex: 1;
            margin: 0 auto;
            width: 100%;
            padding: var(--defaultPadding);
        }
    }

}

//hide button on mobile
.tgWidthBtn {
    display: none;
}

//show button only on desktop
@include mediaXl {
    .tgWidthBtn {
        display: block;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
    }
}