@import "MarkdownFormatted.scss";
@import "mixins.scss";

div.faqContentMD{
    @include markdownFormatted;
}
.faq{
    .category-d{
        @include mediaXl{
            position: sticky;
            top: 90px;
        }
        .ant-anchor-link-active{
            font-weight: bold   ;
        }
    }
    // .faqTagsArrangement{
    //     display: flex;
    //     flex-direction: column;
    //     @include mediaXl{
    //         display: flex;
    //         flex-direction: row;
    //     }
    // }
    .ant-tag-checkable{
        background-color: #e6f4ff;
        color: #1677ff;
    }
    .ant-tag-checkable-checked{
        border-color: #108ee9;
        // color: white;
    }

}