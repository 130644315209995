@import "./../common.scss";

.projectsList {
    table {
        margin: var(--defaultPadding) 0;
        width: 100%;

        tr {
            td {
                ul {
                    list-style-type:none;
                    margin-left: 0;
                }

                &.collectionsAndOptions {
                    text-align: center;
                    small {
                        font-style: italic;
                        font-weight: 500;
                        
                    }
                    ul.options{
                        
                        margin-left:0;
                        list-style-type:none;
                        border-top: 1px solid var(--defaultTitleColor);
                        font-size: 0.8em;
                        margin-top:.5em;
                        padding-top:.5em;
                    }
                }
            }
        }
    }
}