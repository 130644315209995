.user-guide-card {
    .legend {
        transition: all .25s ease-in-out;
        display: inline-flex;
        position: relative !important;
        padding: .25em;
        width: 50em;
    }

    a.top-link {
        transition: all .25s ease-in-out;
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-flex;
        color: #F8F8F8;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        margin: 0 2em 2em 0;
        border-radius: 50%;
        padding: .25em;
        width: 1em;
        height: 1em;
        background-color: #444444;
    }

    .release,
    .environment,
    .engine,
    .scope {
        color: #FFFFFF;
        font-size: 10pt;
        border-radius: 8pt;
        padding-left: 5pt;
        padding-right: 5pt;
        margin-right: 0pt;
        margin-left: 10pt;
    }

    .release {
        background-color: #FF69B4;
    }

    .environment {
        background-color: #66CCCC;
    }

    .engine {
        background-color: #6666FF;
    }

    .scope {
        background-color: #F87217;
    }

    .down:after,
    .up:after {
        display: inline-block;
        width: 20pt;
        color: #000000;
        margin-left: 10pt;
        font-size: 10pt;
        border-radius: 8pt;
        text-align: center;
    }

    .up:after {
        background-color: #D8BFD8;
        content: "↑";
    }

    .down:after {
        background-color: #F0E68C;
        content: "↓";
    }

    .edit {
        display: block;
        text-align: right;
        padding-right: 20pt;
        font-size: 10pt;
    }

    .label {
        background-color: #F0F0F0;
        border-radius: 5pt;
        padding-left: 5pt;
        padding-right: 5pt;
    }

    h2 {
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 30px;
        line-height: 1.2666666666666666;
    }

    h3 {
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 24px;
        line-height: 1.3333333333333333;
    }

    h4 {
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
    }

    p {
        margin-bottom: 1em;
        color: rgba(0, 0, 0, 0.88);
        font-size: 16px;
        line-height: 1.5;
    }

    .ant-typography {
        margin-bottom: 14px;

        pre {
            padding: 0.4em 0.6em;
            white-space: pre-wrap;
            word-wrap: break-word;
            background: rgba(150, 150, 150, 0.1);
            border: 1px solid rgba(100, 100, 100, 0.2);
            border-radius: 3px;
            font-family: var(--ant-font-family-code);
        }

        blockquote {
            padding-inline: 0.6em 0;
            padding-block: 0;
            border-inline-start: 4px solid rgba(100, 100, 100, 0.2);
            border-inline-start-width: 4px;
            border-inline-start-style: solid;
            border-inline-start-color: rgba(100, 100, 100, 0.2);
            opacity: 0.85;
        }
    }

    .ant-table-content {
        margin: 24px 0 32px;
        transform: translate(0);
    }

    table {
        display: table;
        text-align: start;
        border-radius: 8px 8px 0 0;
        border-collapse: separate;
        border-spacing: 0;
        min-width: 100%;
        table-layout: auto;

        tr:first-child>*:first-child {
            border-start-start-radius: 8px;
        }

        tr th {
            position: relative;
            padding: 16px 16px;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #fafafa;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
            white-space: nowrap;

            &:before {
                position: absolute;
                top: 50%;
                inset-inline-end: 0;
                width: 1px;
                height: 1.6em;
                background-color: #f0f0f0;
                transform: translateY(-50%);
                transition: background-color 0.2s;
                content: "";
            }
        }

        tr td {
            padding: 16px 16px;
            border-bottom: 1px solid #f0f0f0;
            transition: background 0.2s ease;
            // &:first-child, &:last-child {
            //   white-space: nowrap;
            // }
            white-space: nowrap;
        }

        tr:hover td {
            background: #f5f5f5;
        }
    }
}