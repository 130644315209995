@import 'mixins.scss';
@import "AntFormatted.scss";

.guidedTourCategories {
    @include antFormatted;



    ul {
        padding-left: 0;
        list-style: none;

        li {
            margin-top: 2rem;
        }
    }

}