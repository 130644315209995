.inviteUsers {
    .rolesContainer {

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

        .roles {
            margin-top: var(--defaultPadding);
            display: flex;
            flex-direction: column;
            gap: calc(var(--defaultPadding) / 3);

            >div {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
            }
        }

    }
}