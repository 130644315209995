@import 'mixins';
* {
    box-sizing: border-box;
}


html {
    background-color: var(--defaultBgContent);
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.userPill {
    &.green {
        color: var(--colorGreenSuccess);
    }

    &.red {
        color: var(--colorRedFailure);
    }

    &.orange {
        color: var(--colorOrangeWarning);
    }
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--colorSecondary) !important;
}

.ant-tabs-ink-bar {

    background: var(--colorSecondary) !important;

}


.paper {
    background-color: var(--defaultPaper);
    border-radius: var(--defaultBorderRadius);
    box-shadow: 0px 3px 5px hsla(0, 0%, 0%, .1);

    padding: var(--defaultPadding);
    margin-bottom: var(--defaultPadding);


    &.inset {
        box-shadow: inset 0px 0px 3px hsla(0, 0%, 0%, .3);
    }
}



.capitalize {
    text-transform: capitalize;
}

.customButton {
    background-color: #c8403d;
    color: white;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    padding: 0.5em 2em;
    border-radius: --var(--defaultBorderRadius);
    border: none;
    cursor: pointer !important;
    transition: all .15s ease-out;
    box-shadow: 0px 2px 3px hsla(0, 0%, 0%, .3);

    >svg {
        transform-origin: center right;
        transform: scale(1.4);
    }

    .load {
        color: white;
    }

    &:hover {
        transform: translateY(-4px) scale(1.05);
        box-shadow: 0px 5px 6px hsla(0, 0%, 0%, .1);
    }

    &:active {
        transform: translateY(0px) scale(.97);
        box-shadow: 0px 2px 3px hsla(0, 0%, 0%, .3);
    }

    &.success {
        background-color: var(--colorGreenSuccess);
    }

    &.disabled {
        opacity: .4;
    }

    &.base {
        background-color: var(--defaultNeteriumColor);
        // padding: 0.5em 1em;
    }

    &.transparent {
        background: transparent;
        box-shadow: none;
        color: #a4a4a4;

        padding: 0;

        &:hover {
            color: #787878;
            transform: translateY(0px) scale(1);
            box-shadow: 0px 5px 6px hsla(0, 0%, 0%, .1);
        }
    }
}

.customSmallButton {
    @extend .customButton;
    font-size: 0.8em;
    padding: 1em 2em;

    border-radius: var(--defaultBorderRadius);
    font-weight: 700;
}

.customSuperSmallButton {
    @extend .customButton;
    font-size: 0.7em;
    padding: .7em 1em;

    border-radius: var(--defaultBorderRadius);
    font-weight: 700;

    >svg {
        transform-origin: center right;
        transform: scale(1);
    }
}

.customNegativeButton {
    color: var(--defaultTextColor);
    cursor: pointer;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 0.5em 2em;
    border: none;
    transition: all .15s ease-out;
    background-color: var(--defaultBgContent);
    border-radius: var(--defaultBorderRadius);

    svg {
        transform-origin: center right;
        transform: scale(1.4);
    }

    &:hover,
    &.active {
        color: white;
        background-color: var(--defaultNeteriumColor);
    }

    &:disabled {
        opacity: .3;
        cursor: auto;
    }

}

input,
textarea,
select {
    border: 1px solid var(--defaultNeteriumColor);
    background-color: white;
    outline: none;
    border-radius: var(--defaultBorderRadius);
    padding: 5px;

    &:focus {
        border: 1px solid var(--defaultNeteriumColor);
    }
}

.customForm {
    @extend .paper;
    padding: var(--defaultPadding);
    display: flex;
    flex-direction: column;
    gap: var(--defaultGap);

    .animatedButton {
        flex-grow: 0;
        align-self: flex-end;
    }
}

.formGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--defaultGap);

    label {
        color: var(--defaultNeteriumColor);
        width: 120px;
    }

    input,
    textarea,
    select {
        padding: 10px 5px;
        flex: 1;
    }

    ::placeholder {
        color: var(--defaultNeteriumColor);
        opacity: 0.75;
        font-size: 1rem;
    }

    input {
        // border: none;
        padding: 15px;

        &:disabled {
            color: var(--defaultNeteriumColor);
            opacity: 0.75;
            font-size: 1rem;
        }
    }

    textarea {
        height: 150px;
    }
}

.animatedButton {
    overflow: hidden;
    position: relative;
    border: none;
    background-color: var(--defaultNeteriumColor);
    padding: 15px 20px;
    border-radius: var(--defaultBorderRadius);
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    text-transform: uppercase;


    &:before {
        z-index: 1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--defaultTitleColor);
        border-radius: var(--defaultBorderRadius);
        transform: translate(-100%, 0%);
        transform-origin: center center;
        transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    svg {
        position: relative;
        margin-right: 7px;
        z-index: 2;
        transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    .buttonText {
        position: relative;
        z-index: 2;
        transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    &:hover {
        svg {
            color: white; //$neteriumBlue;
            transform: translate(-3px, 0);
            transform: scale(1.2);
        }

        .buttonText {
            color: white; //$neteriumBlue;
        }

        &:before {
            transform: translate(0, 0);
        }
    }
}

.autoGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: var(--defaultGap);

    @include mediaMd {
        grid-template-columns: 1fr;
    }
}

.grid3Cols {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--defaultGap);

    @include mediaMd {
        grid-template-columns: 1fr;
    }
}

.grid2Cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--defaultGap);

    @include mediaMd {
        grid-template-columns: 1fr;
    }
}

.grid1Col {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--defaultGap);
}

table.customTable {
    width: 100%;
    overflow-x: auto;

    tr {
        th {
            color: var(--defaultTitleColor);
            text-align: left;
            padding: 0 0 5px .3em;
        }

        td {
            padding: 5px 10px;
            border: 1px solid var(--defaultTitleColorSemiOpaque);
            vertical-align: middle;

            table {
                td {
                    background-color: var(--defaultTitleColorSemiOpaque);
                    text-transform: uppercase;
                    text-align: center;
                    padding: 3px;
                }
            }

            &.actionsTable {
                text-align: center;
            }
        }
    }
}

.pill {
    &.green {
        color: var(--colorGreenSuccess);
    }

    &.red {
        color: var(--colorRedFailure);
    }
}

.cellBg {
    color: white;

    &.number {
        text-align: right;
    }

    &.success {
        background-color: var(--colorGreenSuccess);
    }

    &.failure {
        background-color: var(--colorRedFailure);
    }

    &.warning {
        background-color: silver; //var(--colorOrangeWarning);
    }
}

.pillBg {
    color: white;
    padding: 3px 10px;
    border-radius: var(--defaultBorderRadius);
    font-weight: 700;

    small {
        font-size: 0.8em;

    }

    &.option {
        color: white;
        background-color: var(--defaultTitleColor);
        margin-left: .5em;
        padding: 2px 6px;
    }

    &.jetscan {
        background-color: var(--colorJetscan);
    }

    &.jetflow {
        background-color: var(--colorJetflow);
    }

    &.success {
        background-color: var(--colorGreenSuccess);
    }

    &.failure {
        background-color: var(--colorRedFailure);
    }

    &.warning {
        background-color: var(--colorOrangeWarning);
    }
}

.jsonCode {
    >div {
        >pre {
            padding: var(--defaultPadding);
            border-radius: var(--defaultBorderRadius);
        }

    }
}

.actionsCentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--defaultPadding);
}

.tooltip {
    position: relative;
    /* making the .tooltip span a container for the tooltip text */
    border-bottom: 1px dashed #000;
    /* little indicater to indicate it's hoverable */
}

.tooltip:before {
    content: attr(data-text);
    font-size: .5rem;
    position: absolute;
    top: -175%;
    transform: translateY(-50%) translateX(-50px);
    right: 0%;
    width: 120px;
    padding: 10px 5px;
    border-radius: 4px;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    text-align: center;
    display: none;
}

.tooltip:hover:before {
    display: block;
    margin: initial;   
    left: -100%;
    margin-right: 15px;
}

form p.formNote {
    font-style: italic;
    background-color: rgba(255, 255, 0, .2);
    padding: calc(var(--defaultPadding) / 2);
    border-radius: var(--defaultBorderRadius);

    span.formNoteTip {
        margin-right: .5em;
        font-weight: 600;

        svg {
            color: rgb(255, 230, 0);

        }
    }
}

