.createPackage,.createNamespace {
    padding: var(--defaultPadding);
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);

    div.formGroup {
        display: flex;

        label {
            width: 120px;
            color: var(--defaultTextColor);
            small{
                font-style:italic;
            }
        }

        input,
        select,
        textarea {
            flex: 1;
            border: 1px solid silver;
            border-radius: var(--defaultBorderRadius);
            padding: 5px;
            font-family: inherit;
        }


    }

    .actions {
        display: flex;
        justify-content: flex-end;
        gap:var(--defaultPadding);
    }
}