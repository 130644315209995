.errorContainer {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg{
        font-size: 1.3rem;
        margin-right: .3em;
        color: var(--colorOrangeWarning);
    }
}