.optionsGrid{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--defaultGap);

    
}
.optionsCode {
    
        margin: 1em 0;
        display: block;
        padding: var(--defaultPadding);
        color: white;
        background-color: black;
        border-radius: calc(var(--defaultBorderRadius) / 2);
    
}