@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


:root {

    /*ANT DESIGN*/
    --colorPrimary: #334256;
    --colorSecondary: #82b9d6;
    --colorBgContainer: #ffffff;

    /*Colors*/
    --defaultBg: #334256;
    --defaultBgContent: rgba(172,198,248,.2);
    --defaultNeteriumRed: #c8403d;
    --defaultNeteriumColor: #334256;
    --defaultTitleColor: #82b9d6;
    --defaultTitleColorSemiOpaque:  rgba(162, 216, 237, 0.5);
    --defaultTitleColorLowOpaque:  rgba(162, 216, 237, 0.2);
    --colorJetscan: #38bdf8;
    --colorJetflow: #f59e0b;
    --defaultSidebarText: #334256;
    --defaultPaper: hsla(0, 0%, 100%, 1);
    --defaultTextColor:#333335;

    --colorGreenSuccess: #22c55e;
    --colorRedFailure: #ef4444;
    --colorOrangeWarning: #f59e0b;


    --colorEnvDev: #38bdf8;
    --colorEnvOnPremise: #900093;
    --colorEnvSandbox: #22c55e;
    --colorEnvTest: #f59e0b;
    --colorEnvProd: #c8403d;


    /*Sizes*/
    --defaultFontSize: 16px;
    --defaultMaxWidth: 1536px;
    --defaultFontWeight: 300;
    --defaultPadding: 24px;
    --defaultGap: 15px;
    --defaultLineHeight: 1.4em;
    --defaultLetterSpacing:.03em;
    --defaultBorderRadius: 0px;

    --defaultNavBarHeight: 75px;

    --defaultSizeH1: 29px;
    --defaultSizeH2: 26px;
    --defaultSizeH3: 24px;
    --defaultSizeH4: 22px;
    --defaultSizeH5: 20px;
    --defaultSizeH6: 18px;
}
/* @media (prefers-color-scheme: dark) {
  :root {
    --defaultTextColor:#ffffff;
    --defaultSidebarText: #ffffff;
    --defaultBgContent: #212429;
    --defaultBg: #212429;
    --defaultPaper: #2a2d32;
    --defaultTitleColor: #ffffff;
    --defaultTitleColorSemiOpaque:  rgba(255,255,255, 0.2);
    --defaultTitleColorLowOpaque:  rgba(255,255,255, 0.2);
  }
  body{
    color: var(--defaultTextColor);
}
} */