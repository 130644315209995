@import 'mixins.scss';
@import "AntFormatted.scss";

.releaseNotes {
    @include antFormatted;



    .releaseNotesContent {

        ul {
            padding-left: 0;
            list-style: none;

            li {
                margin-top: 2rem;
            }
        }
    }
}