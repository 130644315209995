.list-status{
    .list-status-card-nav .ant-tabs-nav {
        margin: 0 0 0 1rem;
      }
    .list-status-card-nav .ant-card-actions li:first-of-type {
        margin: 0;
    }
    .list-status-card-nav .ant-card-body {
        padding: 0;
    }
    .recharts-legend-item{
        cursor:pointer;
        font-size: 1.2em;
    }
}