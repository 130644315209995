@import "./../common.scss";

.techDetails {
    padding: var(--defaultPadding) 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--defaultGap);

    @include mediaMd{
        grid-template-columns: 1fr;
    }

    p {
        background-color: var(--defaultTitleColorSemiOpaque);
        padding: calc(var(--defaultPadding) / 2);
        border-radius: var(--defaultBorderRadius);
    }
}
