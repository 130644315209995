@import "mixins.scss";

nav.navbar {
    z-index: 1009;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    &.DEV {
        border-top: 5px solid var(--colorEnvDev);
    }

    &.ON_PREMISE {
        border-top: 5px solid var(--colorEnvOnPremise);
    }

    &.PROD {
        border-top: 5px solid var(--colorEnvProd);
    }

    &.TEST {
        border-top: 5px solid var(--colorEnvTest);
    }

    &.SANDBOX {
        border-top: 5px solid var(--colorEnvSandbox);
    }

    .topBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        align-items: center;


        transition: all .7s ease-out;
        height: var(--defaultNavBarHeight);
        padding: 0 var(--defaultPadding);
        // background-color: var(--colorPrimary);
        background: rgb(0, 49, 81);
        background: linear-gradient(180deg, rgba(0, 49, 81, 1) 0%, rgba(0, 110, 183, 1) 100%);




        .logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: white;

            >a {
                width: 140px;
            }
        }



        div.tRContent {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            span.namespace {
                display: none;
                font-size: 0.9rem;
                font-weight: 700;
                color: white;
                // padding: 3px calc(var(--defaultPadding) /2);

                @include mediaMd {
                    display: inline-block;
                }
            }

            ul.links {

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: var(--defaultGap);

                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;

                    &.envSelector {
                        padding-right: var(--defaultPadding);
                        border-right: 1px solid hsla(0, 0%, 100%, 0.2);
                    }

                    select.environment {
                        /* Reset */
                        appearance: none;
                        border: 0;
                        outline: 0;
                        font: inherit;
                        /* Personalize */
                        width: 15em;
                        height: 3em;
                        padding: 0 4em 0 1em;
                        background: url(../img/selectBg.svg) no-repeat right 0.8em center / 1.4em,
                            linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em);
                        color: white;
                        border-radius: 0.25em;
                        box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
                        cursor: pointer;
                        font-weight: 700;
                        position: relative;

                        &.DEV {
                            border-bottom: 5px solid var(--colorEnvDev);
                        }

                        &.ON_PREMISE {
                            border-bottom: 5px solid var(--colorEnvOnPremise);
                        }

                        &.PROD {
                            border-bottom: 3px solid var(--colorEnvProd);
                        }

                        &.TEST {
                            border-bottom: 3px solid var(--colorEnvTest);
                        }

                        &.SANDBOX {
                            border-bottom: 3px solid var(--colorEnvSandbox);
                        }


                        @include mediaSm {
                            width: 10rem;
                        }

                        /* <option> colors */
                        option {
                            color: inherit;
                            background-color: #320a28;
                        }

                        /* Remove focus outline */
                        &:focus {
                            outline: none;
                        }

                        /* Remove IE arrow */
                        &::-ms-expand {
                            display: none;
                        }

                        //disabled
                        &:disabled {
                            opacity: .4;
                            cursor: not-allowed;
                        }

                    }

                    >a,
                    button {
                        background-color: transparent;
                        cursor: pointer;
                        border: none;
                        color: white;
                        font-size: 0.9rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        display: block;
                        padding: 15px 0;
                        text-decoration: none;

                        .buttonContent {
                            display: none;
                        }

                        @include mediaMd {
                            .buttonContent {
                                display: inline-block;
                            }
                        }

                        button {
                            padding: 0;
                            margin: 0;
                        }

                        // >svg {
                        //     // width: 200px;
                        // }

                        // &:after {
                        //     content: "";
                        //     display: block;
                        //     position: absolute;
                        //     bottom: 6px;
                        //     left: 0;
                        //     width: 0%;
                        //     height: 3px;
                        //     background-color: var(--defaultNeteriumRed);
                        //     transition: all 0.3s ease-out;
                        // }

                        // &:hover {
                        //     &:after {
                        //         width: 100%;
                        //     }
                        // }
                    }
                }

            }
        }
    }



}

.userMenuDropdown {
    padding-top: 10px;

    ul {
        margin: 0 -15px !important;
    }
}