@import './mixins.scss';

.page-header-breadcrumbs .ant-breadcrumb-link {
    text-transform: capitalize !important;
}
.ant-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  text-transform: capitalize !important;
}

h1.page-title {
    text-align: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0;
}

.env-selector {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
}

@include mediaXl {
  h1.page-title{
    font-size: 2.5rem;
    text-align: left;
  }
  .env-selector {
      flex-direction: row;
  }
}