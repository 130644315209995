@import 'mixins.scss';
@import "AntFormatted.scss";

.resourceCatCard {
    height: 100%;

    .ant-card,
    .ant-card-cover {
        overflow: hidden;
    }

    .resourceCatCardCover {
        background-image: url('../img/resourceCover.png');
        background-position: center center;
        background-size: cover;
        font-size: 1.3em;
        text-align: center;
        height: 100px;

        padding: 1rem;


        transition: .3s ease-in-out;

        .coverTitle {
            color: white;
            font-weight: bold;

        }
    }

    &:hover {
        .resourceCatCardCover {
            transform: scale(1.2);
        }
    }
}



.resourcesCategories {
    @include antFormatted;

    ul {
        padding-left: 0;
        list-style: none;

        li {
            margin-top: 2rem;
        }
    }

}